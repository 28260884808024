export default {
  'view.forums.forum.searchLabel': 'Szukaj',

  'view.forums.forum.forumLabel': 'Forum',
  'view.forums.forum.forumAllOption': 'Wszystkie fora',

  'view.forums.forum.languageLabel': 'Język',

  'view.forums.forum.sortLabel': 'Sortowanie',
  'view.forums.forum.latestPostAscOption': 'Data ostatniej odpowiedzi ― Rosnąco',
  'view.forums.forum.latestPostDescOption': 'Data ostatniej odpowiedzi ― Malejąco',
  'view.forums.forum.createdAtAscOption': 'Data publikacji ― Rosnąco',
  'view.forums.forum.createdAtDescOption': 'Data publikacji ― Malejąco',

  'view.forums.forum.subjectText': 'Temat',
  'view.forums.forum.languageText': 'Język',
  'view.forums.forum.viewsText': 'Odsłony',
  'view.forums.forum.repliesText': 'Odpowiedzi',

  'view.forums.forum.descriptionStartedText': 'rozpoczęto',
  'view.forums.forum.descriptionRepliedText': 'odpisano',
  'view.forums.forum.descriptionByText': 'przez',

  'view.forums.forum.emptyListText': 'Przykro nam, ale żaden wynik nie spełnia twoich kryteriów wyszukiwania.',

  'view.forums.index.lookingForShoutboxHeadingText': 'Pssst... szukasz shoutboxa?',
  'view.forums.index.lookingForShoutboxDescriptionText': 'To już nie te czasy, przyjacielu... Ale wciąż możesz dołączyć do naszego serwera na platformie'
}
