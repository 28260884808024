export default {
  'view.images.new.title': 'New Image',

  'view.images.new.fileLabel': 'File',

  'view.images.new.tagLabel': 'Tag',
  'view.images.new.tagAdvancementOption': 'Advancement',
  'view.images.new.tagBossOption': 'Boss',
  'view.images.new.tagCollectionOption': 'Collection',
  'view.images.new.tagEventOption': 'Event',
  'view.images.new.tagFansiteEventOption': 'Fansite Event',
  'view.images.new.tagFriendsOption': 'Friends',
  'view.images.new.tagFunnyOption': 'Funny',
  'view.images.new.tagLootOption': 'Loot',
  'view.images.new.tagOldTibiaOption': 'Old Tibia',
  'view.images.new.tagTrapandoOption': 'Trapando',
  'view.images.new.tagOtherOption': 'Other',
  'view.images.new.tagBlogRookieTeamOption': 'Blog (Rookie Team)',
  'view.images.new.tagOtherRookieTeamOption': 'Other (Rookie Team)',

  'view.images.new.uploadButton': 'Upload',

  'view.images.new.createImageToast': 'You have successfully uploaded an image.'
}
