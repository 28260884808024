export default {
  'view.forums.index.title': 'Forums',

  'view.forums.index.forumText': 'Forum',
  'view.forums.index.threadsText': 'Threads',
  'view.forums.index.postsText': 'Posts',

  'view.forums.index.forumContestsEventsText': 'Contests/Events',
  'view.forums.index.forumReportsProposalsText': 'Reports/Proposals',
  'view.forums.index.forumGeneralText': 'General',
  'view.forums.index.forumScreenshotsVideosText': 'Screenshots/Videos',
  'view.forums.index.forumTradeText': 'Trade',
  'view.forums.index.forumHelpQuestionsText': 'Help/Questions',
  'view.forums.index.forumMysteriandoText': 'Mysteriando',
  'view.forums.index.forumHydeparkText': 'Hydepark',
  'view.forums.index.forumArchiveText': 'Archive',
  'view.forums.index.forumArticlesText': 'Articles',
  'view.forums.index.forumAnnouncementsText': 'Announcements',

  'view.forums.index.latestPostText': 'Latest Post',
  'view.forums.index.inText': 'in',

  'view.forums.index.emptyForumText': 'There are no threads in this forum, yet.',

  'view.forums.index.topUsersText': 'Top Users',
  'view.forums.index.topThreadsText': 'Top Threads',

  'view.forums.index.emptyListText': 'Sorry, but no results match your search criteria.',

  'view.forums.index.lookingForShoutboxHeadingText': 'Pssst... are you looking for the shoutbox?',
  'view.forums.index.lookingForShoutboxDescriptionText': 'Those days are gone, my friend. But you can still pay a visit to our server on'
}
