export default {
  'view.images.new.title': 'Nowy obrazek',

  'view.images.new.fileLabel': 'Plik',

  'view.images.new.tagLabel': 'Tag',
  'view.images.new.tagAdvancementOption': 'Awans',
  'view.images.new.tagBossOption': 'Boss',
  'view.images.new.tagCollectionOption': 'Kolekcja',
  'view.images.new.tagEventOption': 'Wydarzenie',
  'view.images.new.tagFansiteEventOption': 'Fansite Event',
  'view.images.new.tagFriendsOption': 'Przyjaciele',
  'view.images.new.tagFunnyOption': 'Śmieszne',
  'view.images.new.tagLootOption': 'Loot',
  'view.images.new.tagOldTibiaOption': 'Stara Tibia',
  'view.images.new.tagTrapandoOption': 'Trapando',
  'view.images.new.tagOtherOption': 'Inne',
  'view.images.new.tagBlogRookieTeamOption': 'Blog (Rookie Team)',
  'view.images.new.tagOtherRookieTeamOption': 'Inne (Rookie Team)',

  'view.images.new.uploadButton': 'Prześlij',

  'view.images.new.createImageToast': 'Pomyślnie dodałeś obrazek.'
}
