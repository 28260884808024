import { createRouter, createMemoryHistory, createWebHistory } from 'vue-router'

import store from '@/store'

const routes = [
  {
    path: '/blogs',
    name: 'blogs',
    component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
    children: [
      {
        path: '',
        name: 'blogs.index',
        alias: ['/', '/blog'],
        component: () => import(/* webpackChunkName: 'blogs.index' */ '@/views/Blogs/IndexView'),
        meta: {
          title: '$t:view.blog.index.title'
        }
      },
      {
        path: 'new',
        name: 'blogs.new',
        component: () => import(/* webpackChunkName: 'blogs.new' */ '@/views/Blogs/NewView'),
        meta: {
          title: '$t:view.blog.new.title',
          view: {
            extensible: true
          }
        }
      },
      {
        path: ':id',
        alias: ['/blog/:id'],
        props: true,
        name: 'blogs.blog',
        component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
        children: [
          {
            path: ':slug?',
            props: true,
            name: 'blogs.blog.index',
            component: () => import(/* webpackChunkName: 'blogs.blog.index' */ '@/views/Blogs/Blog/IndexView')
          },
          {
            path: 'edit',
            name: 'blogs.blog.edit',
            component: () => import(/* webpackChunkName: 'blogs.blog.edit' */ '@/views/Blogs/Blog/EditView'),
            meta: {
              title: '$t:view.blog.edit.title',
              view: {
                extensible: true
              }
            }
          }
        ]
      }
    ]
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
    children: [
      {
        path: '',
        name: 'users.index',
        component: () => import(/* webpackChunkName: 'users.index' */ '@/views/Users/IndexView'),
        meta: {
          title: '$t:view.users.index.title'
        }
      },
      {
        path: ':id/:slug?',
        props: true,
        name: 'users.user.index',
        component: () => import(/* webpackChunkName: 'users.user.index' */ '@/views/Users/User/IndexView')
      }
    ]
  },
  {
    path: '/library',
    name: 'library',
    component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
    children: [
      {
        path: '',
        name: 'library.index',
        component: () => import(/* webpackChunkName: 'library.index' */ '@/views/Library/IndexView'),
        meta: {
          title: '$t:view.library.index.title'
        }
      },
      {
        path: 'achievements',
        name: 'library.achievements',
        component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
        children: [
          {
            path: '',
            name: 'library.achievements.index',
            component: () => import(/* webpackChunkName: 'library.achievements.index' */ '@/views/Library/Achievements/IndexView'),
            meta: {
              title: '$t:view.library.achievements.index.title'
            }
          },
          {
            path: ':id/:slug?',
            props: true,
            name: 'library.achievements.achievement',
            component: () => import(/* webpackChunkName: 'library.achievements.achievement' */ '@/views/Library/Achievements/AchievementView')
          }
        ]
      },
      {
        path: 'books',
        name: 'library.books',
        component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
        children: [
          // {
          //   path: '',
          //   name: 'library.books.index',
          //   component: () => import(/* webpackChunkName: 'library.books.index' */ '@/views/Library/Books/IndexView'),
          //   meta: {
          //     title: '$t:view.library.books.index.title'
          //   }
          // },
          {
            path: ':id/:slug?',
            props: true,
            name: 'library.books.book',
            component: () => import(/* webpackChunkName: 'library.books.book' */ '@/views/Library/Books/BookView')
          }
        ]
      },
      {
        path: 'creatures',
        name: 'library.creatures',
        component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
        children: [
          {
            path: '',
            name: 'library.creatures.index',
            component: () => import(/* webpackChunkName: 'library.creatures.index' */ '@/views/Library/Creatures/IndexView'),
            meta: {
              title: '$t:view.library.creatures.index.title'
            }
          },
          {
            path: ':id/:slug?',
            props: true,
            name: 'library.creatures.creature',
            component: () => import(/* webpackChunkName: 'library.creatures.creature' */ '@/views/Library/Creatures/CreatureView')
          }
        ]
      },
      {
        path: 'npcs',
        name: 'library.npcs',
        component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
        children: [
          {
            path: '',
            name: 'library.npcs.index',
            component: () => import(/* webpackChunkName: 'library.npcs.index' */ '@/views/Library/NPCs/IndexView'),
            meta: {
              title: '$t:view.library.npcs.index.title'
            }
          },
          {
            path: ':id/:slug?',
            props: true,
            name: 'library.npcs.npc',
            component: () => import(/* webpackChunkName: 'library.npcs.npc' */ '@/views/Library/NPCs/NPCView')
          }
        ]
      },
      {
        path: 'items',
        name: 'library.items',
        component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
        children: [
          {
            path: '',
            name: 'library.items.index',
            component: () => import(/* webpackChunkName: 'library.items.index' */ '@/views/Library/Items/IndexView'),
            meta: {
              title: '$t:view.library.items.index.title'
            }
          },
          {
            path: ':id/:slug?',
            props: true,
            name: 'library.items.item',
            component: () => import(/* webpackChunkName: 'library.items.item' */ '@/views/Library/Items/ItemView')
          },
          {
            path: 'containers',
            name: 'library.items.containers.index',
            component: () => import(/* webpackChunkName: 'library.items.containers.index' */ '@/views/Library/Items/ContainersView'),
            meta: {
              title: '$t:view.library.items.containers.title'
            }
          },
          {
            path: 'amulets',
            name: 'library.items.amulets.index',
            component: () => import(/* webpackChunkName: 'library.items.amulets.index' */ '@/views/Library/Items/AmuletsView'),
            meta: {
              title: '$t:view.library.items.amulets.title'
            }
          },
          {
            path: 'rings',
            name: 'library.items.rings.index',
            component: () => import(/* webpackChunkName: 'library.items.rings.index' */ '@/views/Library/Items/RingsView'),
            meta: {
              title: '$t:view.library.items.rings.title'
            }
          },
          {
            path: 'helmets',
            name: 'library.items.helmets.index',
            component: () => import(/* webpackChunkName: 'library.items.helmets.index' */ '@/views/Library/Items/HelmetsView'),
            meta: {
              title: '$t:view.library.items.helmets.title'
            }
          },
          {
            path: 'armors',
            name: 'library.items.armors.index',
            component: () => import(/* webpackChunkName: 'library.items.armors.index' */ '@/views/Library/Items/ArmorsView'),
            meta: {
              title: '$t:view.library.items.armors.title'
            }
          },
          {
            path: 'legs',
            name: 'library.items.legs.index',
            component: () => import(/* webpackChunkName: 'library.items.legs.index' */ '@/views/Library/Items/LegsView'),
            meta: {
              title: '$t:view.library.items.legs.title'
            }
          },
          {
            path: 'boots',
            name: 'library.items.boots.index',
            component: () => import(/* webpackChunkName: 'library.items.boots.index' */ '@/views/Library/Items/BootsView'),
            meta: {
              title: '$t:view.library.items.boots.title'
            }
          },
          {
            path: 'melee-weapons',
            name: 'library.items.meleeWeapons.index',
            component: () => import(/* webpackChunkName: 'library.items.meleeWeapons.index' */ '@/views/Library/Items/MeleeWeaponsView'),
            meta: {
              title: '$t:view.library.items.meleeWeapons.title'
            }
          },
          {
            path: 'distance-weapons',
            name: 'library.items.distanceWeapons.index',
            component: () => import(/* webpackChunkName: 'library.items.distanceWeapons.index' */ '@/views/Library/Items/DistanceWeaponsView'),
            meta: {
              title: '$t:view.library.items.distanceWeapons.title'
            }
          },
          {
            path: 'shields',
            name: 'library.items.shields.index',
            component: () => import(/* webpackChunkName: 'library.items.shields.index' */ '@/views/Library/Items/ShieldsView'),
            meta: {
              title: '$t:view.library.items.shields.title'
            }
          },
          {
            path: 'tools',
            name: 'library.items.tools.index',
            component: () => import(/* webpackChunkName: 'library.items.tools.index' */ '@/views/Library/Items/ToolsView'),
            meta: {
              title: '$t:view.library.items.tools.title'
            }
          },
          {
            path: 'creature-products',
            name: 'library.items.creatureProducts.index',
            component: () => import(/* webpackChunkName: 'library.items.creatureProducts.index' */ '@/views/Library/Items/CreatureProductsView'),
            meta: {
              title: '$t:view.library.items.creatureProducts.title'
            }
          },
          {
            path: 'currency',
            name: 'library.items.currency.index',
            component: () => import(/* webpackChunkName: 'library.items.currency.index' */ '@/views/Library/Items/CurrencyView'),
            meta: {
              title: '$t:view.library.items.currency.title'
            }
          },
          {
            path: 'food',
            name: 'library.items.food.index',
            component: () => import(/* webpackChunkName: 'library.items.food.index' */ '@/views/Library/Items/FoodView'),
            meta: {
              title: '$t:view.library.items.food.title'
            }
          },
          {
            path: 'liquids',
            name: 'library.items.liquids.index',
            component: () => import(/* webpackChunkName: 'library.items.liquids.index' */ '@/views/Library/Items/LiquidsView'),
            meta: {
              title: '$t:view.library.items.liquids.title'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/posts',
    name: 'posts',
    component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
    children: [
      {
        path: '',
        name: 'posts.index',
        component: () => import(/* webpackChunkName: 'posts.index' */ '@/views/Posts/IndexView'),
        meta: {
          title: '$t:view.posts.index.title'
        }
      }
    ]
  },
  {
    path: '/forums',
    name: 'forums',
    component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
    children: [
      {
        path: '',
        name: 'forums.index',
        component: () => import(/* webpackChunkName: 'forums.index' */ '@/views/Forums/IndexView'),
        meta: {
          title: '$t:view.forums.index.title'
        }
      },
      {
        path: ':id/:slug?',
        props: true,
        name: 'forums.forum.index',
        component: () => import(/* webpackChunkName: 'forums.forum.index' */ '@/views/Forums/Forum/IndexView')
      }
    ]
  },
  {
    path: '/threads',
    name: 'threads',
    component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
    children: [
      {
        path: 'new',
        name: 'threads.new',
        component: () => import(/* webpackChunkName: 'threads.new' */ '@/views/Threads/NewView'),
        meta: {
          title: '$t:view.threads.new.title',
          view: {
            extensible: true
          }
        }
      },
      {
        path: ':id',
        props: true,
        name: 'threads.thread',
        component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
        children: [
          {
            path: ':slug?',
            props: true,
            name: 'threads.thread.index',
            component: () => import(/* webpackChunkName: 'threads.thread.index' */ '@/views/Threads/Thread/IndexView')
          },
          {
            path: 'edit',
            props: true,
            name: 'threads.thread.edit',
            component: () => import(/* webpackChunkName: 'threads.thread.edit' */ '@/views/Threads/Thread/EditView'),
            meta: {
              title: '$t:view.threads.edit.title',
              view: {
                extensible: true
              }
            }
          },
          {
            path: 'new-post',
            name: 'threads.thread.newPost',
            component: () => import(/* webpackChunkName: 'threads.thread.newPost' */ '@/views/Threads/Thread/NewPostView'),
            meta: {
              title: '$t:view.threads.newPost.title',
              view: {
                extensible: true
              }
            }
          },
          {
            path: 'posts/:pid',
            props: true,
            name: 'threads.thread.post',
            component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
            children: [
              {
                path: 'edit-post',
                name: 'threads.thread.post.edit',
                component: () => import(/* webpackChunkName: 'threads.thread.post.edit' */ '@/views/Threads/Thread/Post/EditView'),
                meta: {
                  title: '$t:view.threads.editPost.title',
                  view: {
                    extensible: true
                  }
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/rookstat',
    name: 'rookstat',
    component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
    children: [
      {
        path: '',
        name: 'rookstat.index',
        component: () => import(/* webpackChunkName: 'rookstat.index' */ '@/views/Rookstat/IndexView'),
        meta: {
          title: '$t:view.rookstat.index.title'
        }
      },
      {
        path: 'players',
        name: 'rookstat.players',
        component: () => import(/* webpackChunkName: 'rookstat.players' */ '@/views/EmptyView'),
        children: [
          {
            path: '',
            name: 'rookstat.players.index',
            component: () => import(/* webpackChunkName: 'rookstat.players.index' */ '@/views/Rookstat/Players/IndexView'),
            meta: {
              title: '$t:view.rookstat.players.index.title'
            }
          },
          {
            path: ':id/:slug?',
            props: true,
            name: 'rookstat.players.player',
            component: () => import(/* webpackChunkName: 'rookstat.players.player' */ '@/views/Rookstat/Players/PlayerView')
          }
        ]
      },
      {
        path: 'worlds',
        name: 'rookstat.worlds.index',
        component: () => import(/* webpackChunkName: 'rookstat.worlds.index' */ '@/views/Rookstat/Worlds/IndexView'),
        meta: {
          title: '$t:view.rookstat.worlds.title'
        }
      },
      {
        path: 'advancements',
        name: 'rookstat.advancements.index',
        component: () => import(/* webpackChunkName: 'rookstat.advancements.index' */ '@/views/Rookstat/AdvancementsView'),
        meta: {
          title: '$t:view.rookstat.advancements.title'
        }
      },
      {
        path: 'deaths',
        name: 'rookstat.deaths.index',
        component: () => import(/* webpackChunkName: 'rookstat.deaths.index' */ '@/views/Rookstat/DeathsView'),
        meta: {
          title: '$t:view.rookstat.deaths.title'
        }
      },
      {
        path: 'bosses',
        name: 'rookstat.bosses.index',
        component: () => import(/* webpackChunkName: 'rookstat.bosses.index' */ '@/views/Rookstat/BossesView'),
        meta: {
          title: '$t:view.rookstat.bosses.title'
        }
      },
      {
        path: 'boosted-creatures',
        name: 'rookstat.boostedCreatures.index',
        component: () => import(/* webpackChunkName: 'rookstat.boostedCreatures.index' */ '@/views/Rookstat/BoostedCreaturesView'),
        meta: {
          title: '$t:view.rookstat.boostedCreatures.title'
        }
      },
      {
        path: 'faq',
        name: 'rookstat.frequentlyAskedQuestions.index',
        component: () => import(/* webpackChunkName: 'rookstat.frequentlyAskedQuestions' */ '@/views/Rookstat/FrequentlyAskedQuestionsView'),
        meta: {
          title: '$t:view.rookstat.frequentlyAskedQuestions.title'
        }
      },
      {
        path: 'special-codes',
        name: 'rookstat.specialCodes.index',
        component: () => import(/* webpackChunkName: 'rookstat.specialCodes.index' */ '@/views/Rookstat/SpecialCodesView'),
        meta: {
          title: '$t:view.rookstat.specialCodes.title'
        }
      }
    ]
  },
  {
    path: '/calculators',
    name: 'calculators',
    component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
    children: [
      {
        path: '',
        name: 'calculators.index',
        component: () => import(/* webpackChunkName: 'calculators.index' */ '@/views/Calculators/IndexView'),
        meta: {
          title: '$t:view.calculators.index.title'
        }
      },
      {
        path: 'age-calculator',
        name: 'calculators.ageCalculator',
        component: () => import(/* webpackChunkName: 'calculators.ageCalculator' */ '@/views/Calculators/AgeCalculatorView'),
        meta: {
          title: '$t:view.calculators.ageCalculator.title'
        }
      },
      {
        path: 'equipment-loot-bonus-calculator',
        name: 'calculators.equipmentLootBonusCalculator',
        component: () => import(/* webpackChunkName: 'calculators.equipmentLootBonusCalculator' */ '@/views/Calculators/EquipmentLootBonusCalculatorView'),
        meta: {
          title: '$t:view.calculators.equipmentLootBonusCalculator.title'
        }
      },
      {
        path: 'fishing-calculator',
        name: 'calculators.fishingCalculator',
        component: () => import(/* webpackChunkName: 'calculators.fishingCalculator' */ '@/views/Calculators/FishingCalculatorView'),
        meta: {
          title: '$t:view.calculators.fishingCalculator.title'
        }
      },
      {
        path: 'hunting-time-calculator',
        name: 'calculators.huntingTimeCalculator',
        component: () => import(/* webpackChunkName: 'calculators.huntingTimeCalculator' */ '@/views/Calculators/HuntingTimeCalculatorView'),
        meta: {
          title: '$t:view.calculators.huntingTimeCalculator.title'
        }
      },
      {
        path: 'party-calculator',
        name: 'calculators.partyCalculator',
        component: () => import(/* webpackChunkName: 'calculators.partyCalculator' */ '@/views/Calculators/PartyCalculatorView'),
        meta: {
          title: '$t:view.calculators.partyCalculator.title'
        }
      },
      {
        path: 'player-stats-calculator',
        name: 'calculators.playerStatsCalculator',
        component: () => import(/* webpackChunkName: 'calculators.playerStatsCalculator' */ '@/views/Calculators/PlayerStatsCalculatorView'),
        meta: {
          title: '$t:view.calculators.playerStatsCalculator.title'
        }
      },
      {
        path: 'stamina-calculator',
        name: 'calculators.staminaCalculator',
        component: () => import(/* webpackChunkName: 'calculators.staminaCalculator' */ '@/views/Calculators/StaminaCalculatorView'),
        meta: {
          title: '$t:view.calculators.staminaCalculator.title'
        }
      }
    ]
  },
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
    children: [
      {
        path: '',
        name: 'account.index',
        component: () => import(/* webpackChunkName: 'account.index' */ '@/views/Account/IndexView'),
        meta: {
          title: '$t:view.account.index.title'
        }
      },
      {
        path: 'login',
        name: 'account.login',
        component: () => import(/* webpackChunkName: 'account.login' */ '@/views/Account/LoginView'),
        meta: {
          title: '$t:view.account.login.title'
        }
      },
      {
        path: 'register',
        name: 'account.register',
        component: () => import(/* webpackChunkName: 'account.register' */ '@/views/Account/RegisterView'),
        meta: {
          title: '$t:view.account.register.title'
        }
      },
      {
        path: 'request-activation',
        name: 'account.requestActivation',
        component: () => import(/* webpackChunkName: 'account.requestActivation' */ '@/views/Account/RequestActivationView'),
        meta: {
          title: '$t:view.account.requestActivation.title'
        }
      },
      {
        path: 'reset-password',
        name: 'account.resetPassword',
        component: () => import(/* webpackChunkName: 'account.resetPassword' */ '@/views/Account/ResetPasswordView'),
        meta: {
          title: '$t:view.account.resetPassword.title'
        }
      },
      {
        path: 'change-avatar',
        name: 'account.changeAvatar',
        component: () => import(/* webpackChunkName: 'account.changeAvatar' */ '@/views/Account/ChangeAvatarView'),
        meta: {
          title: '$t:view.account.changeAvatar.title',
          loggedIn: true
        }
      },
      {
        path: 'change-email',
        name: 'account.changeEmail',
        component: () => import(/* webpackChunkName: 'account.changeEmail' */ '@/views/Account/ChangeEmailView'),
        meta: {
          title: '$t:view.account.changeEmail.title',
          loggedIn: true
        }
      },
      {
        path: 'change-password',
        name: 'account.changePassword',
        component: () => import(/* webpackChunkName: 'account.changePassword' */ '@/views/Account/ChangePasswordView'),
        meta: {
          title: '$t:view.account.changePassword.title',
          loggedIn: true
        }
      },
      {
        path: 'warnings',
        name: 'account.warnings',
        component: () => import(/* webpackChunkName: 'account.warnings' */ '@/views/Account/WarningsView'),
        meta: {
          title: '$t:view.account.warnings.title',
          loggedIn: true
        }
      },
      {
        path: 'revoke-sessions',
        name: 'account.revokeSessions',
        component: () => import(/* webpackChunkName: 'account.revokeSessions' */ '@/views/Account/RevokeSessionsView'),
        meta: {
          title: '$t:view.account.revokeSessions.title',
          loggedIn: true
        }
      },
      {
        path: 'anonymize',
        name: 'account.anonymize',
        component: () => import(/* webpackChunkName: 'account.anonymize' */ '@/views/Account/AnonymizeView'),
        meta: {
          title: '$t:view.account.anonymize.title',
          loggedIn: true
        }
      }
    ]
  },
  {
    path: '/images',
    name: 'images',
    component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
    children: [
      {
        path: '',
        name: 'images.index',
        component: () => import(/* webpackChunkName: 'images.index' */ '@/views/Images/IndexView'),
        meta: {
          title: '$t:view.images.index.title'
        }
      },
      {
        path: 'new',
        name: 'images.new',
        component: () => import(/* webpackChunkName: 'images.new' */ '@/views/Images/NewView'),
        meta: {
          title: '$t:view.images.new.title'
        }
      },
      {
        path: ':id',
        props: true,
        name: 'images.image',
        component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
        children: [
          {
            path: '',
            name: 'images.image.index',
            component: () => import(/* webpackChunkName: 'images.image.index' */ '@/views/Images/Image/IndexView')
          },
          {
            path: 'edit',
            name: 'images.image.edit',
            component: () => import(/* webpackChunkName: 'images.image.edit' */ '@/views/Images/Image/EditView'),
            meta: {
              title: '$t:view.images.edit.title'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/warnings',
    name: 'warnings',
    component: () => import(/* webpackChunkName: 'empty' */ '@/views/EmptyView'),
    children: [
      {
        path: 'new',
        name: 'warnings.new',
        component: () => import(/* webpackChunkName: 'warnings.new' */ '@/views/Warnings/NewView'),
        meta: {
          title: '$t:view.warnings.new.title'
        }
      }
    ]
  },
  {
    path: '/fansite-item',
    name: 'fansiteItem',
    component: () => import(/* webpackChunkName: 'fansiteItem' */ '@/views/FansiteItemView'),
    meta: {
      title: '$t:view.fansiteItem.title'
    }
  },
  {
    path: '/rules',
    name: 'rules',
    component: () => import(/* webpackChunkName: 'rules' */ '@/views/RulesView'),
    meta: {
      title: '$t:view.rules.title'
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () => import(/* webpackChunkName: 'privacyPolicy' */ '@/views/PrivacyPolicyView'),
    meta: {
      title: '$t:view.privacyPolicy.title'
    }
  },
  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: 'error' */ '@/views/ErrorView'),
    meta: {
      title: '$t:view.error.title'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: 'notFound' */ '@/views/NotFoundView'),
    meta: {
      title: '$t:view.error.title'
    }
  }
]

const router = createRouter({
  history: (typeof window === 'undefined' ? createMemoryHistory : createWebHistory)(),
  routes,
  scrollBehavior: ({ hash }) => {
    if (hash) {
      return { selector: hash, behavior: 'smooth' }
    }

    return { top: 0, behavior: 'smooth' }
  }
})

router.beforeEach((to, from, next) => {
  if (to.path !== from.path || to.name !== from.name) {
    store.commit('SET_ROUTE_TITLE', {
      path: to.path,
      routeTitle: to.meta?.title ?? null
    })

    store.commit('SET_ROUTE_DESCRIPTION', {
      path: to.path,
      routeDescription: to.meta?.routeDescription ?? 'A Tibia fansite dedicated to Rookgaard and novice islands.'
    })
  }

  const errorRoute = {
    name: 'error'
  }

  if (to.meta.loggedIn === true && !store.state.auth.authenticated) {
    return next(errorRoute)
  }

  return next()
})

export default router
